import React from 'react'
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import Nav from '../components/nav'
import Footer from '../components/footer'

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <Nav />
            <div className="layout">
				<div className="layout__wide padding__large--top">
					<div className="flex flex__space-between">
						<div className="col__80 body">
							<h1>{ entry.frontmatter.heading }</h1>
						</div>
					</div>
				</div>
            </div>
            <main className="layout">
                <div className="layout__wide padding__large body">
                    <div className="flex flex__space-between">
                        <div className="col__45">
                            <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                            <Link to="/" class="btn btn--fill">Back to home &#9658;</Link>
                        </div>
                        <div className="col__45 mobile__off">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				page_title
				meta_description
                heading
			}
			fields {
				slug
			}
			html
		}
	}
`